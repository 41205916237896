<script setup  lang="ts">
import useSubHeaderState from './useSubHeaderState';

const {
    breadcrumbs,
} = useSubHeaderState();

const scrollbarWidth = ref(0);
onMounted(() => {
    scrollbarWidth.value = window.innerWidth - document.body.clientWidth;
});

const elSwipeOverflow = useTemplateRef('elSwipeOverflow');
watch(breadcrumbs, () => {
    elSwipeOverflow.value?.recalculate();
});
</script>

<template>
    <div class="relative flex h-10 w-screen items-center border-b border-b-shade-1 bg-background-1" :style="`width: calc(100vw - ${scrollbarWidth}px);`">
        <BaseSwipeOverflow ref="elSwipeOverflow" start-position="right" slide-class="px-container-gap">
            <div class="flex items-center space-x-2 truncate typo-meta-s-medium">
                <template
                    v-for="(breadcrumb, index) in breadcrumbs"
                    :key="breadcrumb.label"
                >
                    <span v-if="index > 0">/</span>
                    <button

                        class="flex items-center"
                        :class="!!breadcrumb.callback ? 'cursor-pointer underline' : 'cursor-default'"
                        @click="breadcrumb.callback"
                    >
                        <Icon v-if="index === 0" name="tot:caret-left" class="mr-2 block size-4" />
                        <span class="whitespace-nowrap md:hidden">{{ breadcrumb.labelShort ?? breadcrumb.label }}</span>
                        <span class="whitespace-nowrap max-md:hidden">{{ breadcrumb.label }}</span>
                    </button>
                </template>
            </div>
        </BaseSwipeOverflow>

        <div class="absolute left-0 top-0 z-10 flex h-full w-container-gap-capped">
            <div class="h-full w-1/3 bg-background-1 bg-gradient-to-r" />
            <div class="h-full w-2/3 bg-gradient-to-r from-background-1 to-transparent" />
        </div>
        <div class="absolute right-0 top-0 z-10 flex h-full w-container-gap-capped">
            <div class="h-full w-2/3 bg-gradient-to-l from-background-1 to-transparent" />
            <div class="h-full w-1/3 bg-background-1 bg-gradient-to-l" />
        </div>
    </div>
</template>
